import React, { useEffect, useState, forwardRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { reportDownloadApi } from "../../services/reports/reportDownloadApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";
import { attendanceReportDownloadApi } from "../../services/reports/attendanceReport";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Common/Loader";

const AttendanceReport = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterEmpId, setFilterEmpId] = useState('');
  const [loading, setLoading] = useState(false);
  const [allAttendance, setAllAttendance] = useState([]);
  const [error, setError] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastRecordId, setLastRecordId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [excloading, setExcloading] = useState(false)



  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);


  const parseTime = useCallback((timeStr) => {
    if (!timeStr) return null;

    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);

  // const fetchData = async () => {
  //   setLoading(true); // Start loading state

  //   try {
  //     // Fetch data from API
  //     const response = await reportDownloadApi(
  //       accessToken,
  //       filterEmpId,
  //       startDate,
  //       endDate,
  //       "Attendance",
  //       division
  //     );

  //     // Log the entire response to understand its structure
  //     console.log("API Response:", response);

  //     // Check if response and response.data.Attendance are defined and have records
  //     if (!response || !response.data || !Array.isArray(response.data.Attendance)) {
  //       console.error("Unexpected response format:", response);
  //       throw new Error("Invalid response format");
  //     }

  //     // Access the array of attendance records
  //     const dataArray = response.data.Attendance;
  //     console.log("Fetched Data Array:", dataArray);

  //     // Set default dates if not provided
  //     const start = startDate ? new Date(startDate) : new Date("1900-01-01");
  //     const end = endDate ? new Date(endDate) : new Date("2100-01-01");
  //     start.setHours(0,0,0,0)
  //     end.setHours(23,59,59,999)

  //     console.log("Filtering Data with Start Date:", start, "End Date:", end);

  //     // Filter data based on date range and employee ID
  //     const filteredData = dataArray.filter((item) => {
  //       const itemDate = new Date(item.CreatedDate); // Use CreatedDate for filtering
  //       console.log("Filtering Item Date:", itemDate, "Start:", start, "End:", end);
  //       return (itemDate >= start && itemDate <= end) ||
  //         (!filterEmpId || filterEmpId === item.Emp_Attendance__c);
  //     });

  //     console.log("Filtered Data:", filteredData);

  //     // Sort the filtered data
  //     const sortedData = filteredData.sort(
  //       (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate) // Use CreatedDate for sorting
  //     );

  //     console.log("Sorted Data:", sortedData);

  //     // Update state
  //     setAllAttendance(sortedData);
  //     setDataFetched(sortedData.length > 0); // Set dataFetched based on the presence of data

  //   } catch (error) {
  //     console.error("Error fetching tour plans:", error);
  //     setDataFetched(false); // Indicate no data was fetched in case of error
  //   } finally {
  //     setLoading(false); // End loading state
  //   }
  // };

  // const fetchData = async (loadMore = false, id = '') => {
  //   setLoading(true); // Start loading state

  //   try {
  //     // Fetch data from API, use the id (lastRecordId) when loading more
  //     const response = await attendanceReportDownloadApi(
  //       accessToken,
  //       filterEmpId,
  //       startDate,
  //       endDate,
  //       designation,
  //       empId,
  //       loadMore ? id : '', // Pass the id (lastRecordId) if loading more
  //       division
  //     );

  //     // Parse the response data
  //     const dataArray = JSON.parse(response.data);
  //     console.log("API Response:", dataArray);

  //     // Check if data and records are available
  //     if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
  //       setHasMore(false); // No more records to fetch
  //       return;
  //     }

  //     // Filter data based on date range and employee ID
  //     const start = startDate ? new Date(startDate) : new Date("1900-01-01");
  //     const end = endDate ? new Date(endDate) : new Date("2100-01-01");
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(23, 59, 59, 999);

  //     console.log("Filtering Data with Start Date:", start, "End Date:", end);

  //     // Filter data based on date range and employee ID
  //     const filteredData = dataArray.records.filter((item) => {
  //       const itemDate = new Date(item.CreatedDate); // Use CreatedDate for filtering
  //       console.log("Filtering Item Date:", itemDate, "Start:", start, "End:", end);
  //       return (
  //         (itemDate >= start && itemDate <= end) &&
  //         (!filterEmpId || filterEmpId === item.Emp_Attendance__c)
  //       );
  //     });

  //     console.log("Filtered Data:", filteredData);

  //     // Sort the filtered data by CreatedDate in descending order
  //     const sortedData = filteredData.sort(
  //       (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate) // Use CreatedDate for sorting
  //     );

  //     console.log("Sorted Data:", sortedData);

  //     // Extract records and lastRecordId for pagination
  //     const { lastRecordId } = dataArray;

  //     if (loadMore) {
  //       // Append new records to the existing data
  //       setAllAttendance((prevData) => [...prevData, ...sortedData]);
  //     } else {
  //       // Set data for the first load
  //       setAllAttendance(sortedData);
  //     }

  //     // Update the lastRecordId for pagination
  //     setLastRecordId(lastRecordId);

  //     // If there are more records, allow loading more
  //     setHasMore(!!lastRecordId);

  //     // Set dataFetched based on the presence of data
  //     setDataFetched(sortedData.length > 0);
  //   } catch (error) {
  //     console.error("Error fetching attendance data:", error);
  //     setDataFetched(false); // Indicate no data was fetched in case of error
  //   } finally {
  //     setLoading(false); // End loading state
  //   }
  // };
  const fetchData = async (loadMore = false, id = '') => {
    setLoading(true); // Start loading state

    try {
      // Fetch data from API, use the id (lastRecordId) when loading more
      const response = await attendanceReportDownloadApi(
        accessToken,
        filterEmpId,
        startDate,
        endDate,
        designation,
        empId,
        loadMore ? id : '',  // Pass the id (lastRecordId) if loading more
        division
      );
      // Parse the response data
      const dataArray = JSON.parse(response.data);
      // Check if response contains an error message despite status 200
      if (dataArray?.error) {
        toast.error(dataArray?.error); // Show the error message in an alert
        setAllAttendance([]); 
        setDataFetched(false); // Indicate no data was fetched
        setHasMore(false); // No more records to fetch
        return;
      }

      // Check if data and records are available
      if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
        setHasMore(false); // No more records to fetch
        // setAllAttendance([]); 
        // setDataFetched(false); 
        return;
      }

      const { records, lastRecordId } = dataArray;

      // Sort the records in descending order by 'Date1__c' field
      const sortedRecords = records.sort((a, b) => new Date(b.Date1__c) - new Date(a.Date1__c));

      if (loadMore) {
        // Append new sorted records to the existing data and keep the overall list sorted
        setAllAttendance((prevData) =>
          [...prevData, ...sortedRecords].sort((a, b) => new Date(b.Date1__c) - new Date(a.Date1__c))
        );
      } else {
        // Set sorted data for the first load
        setAllAttendance(sortedRecords);
      }

      // Update the lastRecordId for pagination
      setLastRecordId(lastRecordId);

      // If there are more records, allow loading more
      setHasMore(!!lastRecordId);

      console.log("Fetched Records:", sortedRecords);

      // Set dataFetched based on the presence of data
      setDataFetched(records.length > 0);
    } catch (error) {
      console.error("Error fetching DCR data:", error);
      setAllAttendance([]); // Reset data to empty array
      setDataFetched(false); // Indicate no data was fetched in case of error
    } finally {
      setLoading(false); // End loading state
    }
  };


  const handleSubmit = () => {
    fetchData();
  };


  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError('Please enter only numbers.');
    } else {
      setError('');
    }
  };

  // const exportToExcel = async () => {
  //   let allData = [];  // Array to store all the fetched data
  //   let lastRecordId = '';  // Keep track of the last fetched record ID
  //   let hasMore = true;  // Boolean to indicate if more records are available
  //   const limit = 500;  // Limit for the number of records per API call

  //   setExcloading(true);  // Show loading indicator during export process

  //   try {
  //     // Loop until all data is fetched
  //     while (hasMore) {
  //       // Fetch a chunk of data
  //       const response = await attendanceReportDownloadApi(
  //         accessToken,
  //         filterEmpId,
  //         startDate,
  //         endDate,
  //         designation,
  //         empId,
  //         lastRecordId,  // Use lastRecordId to fetch the next batch of records
  //         division
  //       );

  //       // Parse the response
  //       const dataArray = JSON.parse(response.data);

  //       // If no records are returned, stop fetching
  //       if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
  //         hasMore = false;
  //         break;
  //       }

  //       // Filter data based on date range and employee ID
  //       const start = startDate ? new Date(startDate) : new Date("1900-01-01");
  //       const end = endDate ? new Date(endDate) : new Date("2100-01-01");
  //       start.setHours(0, 0, 0, 0);
  //       end.setHours(23, 59, 59, 999);

  //       console.log("Filtering Data with Start Date:", start, "End Date:", end);

  //       // Filter data based on date range and employee ID
  //       const filteredData = dataArray.records.filter((item) => {
  //         const itemDate = new Date(item.CreatedDate); // Use CreatedDate for filtering
  //         console.log("Filtering Item Date:", itemDate, "Start:", start, "End:", end);
  //         return (
  //           (itemDate >= start && itemDate <= end) &&
  //           (!filterEmpId || filterEmpId === item.Emp_Attendance__c)
  //         );
  //       });

  //       console.log("Filtered Data:", filteredData);

  //       // Sort the filtered data by CreatedDate in descending order
  //       const sortedData = filteredData.sort(
  //         (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate) // Use CreatedDate for sorting
  //       );

  //       // Merge the new sorted records into allData
  //       allData = [...allData, ...sortedData];

  //       // Extract the next lastRecordId from the response
  //       lastRecordId = dataArray.lastRecordId;

  //       // If there are no more records, stop the loop
  //       hasMore = !!dataArray.lastRecordId;
  //     }

  //     // After fetching all records, export to Excel
  //     if (allData.length > 0) {
  //       // Generate Excel file from allData
  //       handleExport(allData);
  //     } else {
  //       console.log('No data available to export');
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data for Excel export:", error);
  //   } finally {
  //     setExcloading(false);  // Hide loading indicator after export process
  //   }
  // };

  const exportToExcel = async () => {
    let allData = [];  // Array to store all the fetched data
    let lastRecordIdd = '';  // Keep track of the last fetched record ID
    let hasMore = true;  // Boolean to indicate if more records are available

    setExcloading(true);  // Show loading indicator during export process

    try {
      // Loop until all data is fetched
      while (hasMore) {
        // Fetch a chunk of data
        const response = await attendanceReportDownloadApi(
          accessToken,
          filterEmpId,
          startDate,
          endDate,
          designation,
          empId,
          lastRecordIdd,  // Use lastRecordId to fetch the next batch of records
          division
        );

        // Parse the response data
        const dataArray = JSON.parse(response.data);

        // Check if response contains an error message
        if (dataArray?.error) {
          toast.error(dataArray?.error); // Show the error message in an alert
          setAllAttendance([]); // Reset data to an empty array
          return; // Exit the function if there's an error
        }

        // If no records are returned, stop fetching
        if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
          hasMore = false;
          break;
        }

        // Extract records and the next lastRecordId from the response
        const { records, lastRecordId } = dataArray;

        // Merge the new records into allData
        allData = [...allData, ...records];

        // Update the lastRecordId for the next API call
        lastRecordIdd = lastRecordId;

        // If there are no more records, stop the loop
        hasMore = !!lastRecordId;
      }

      // After fetching all records, sort the data by 'Date1__c' in descending order
      const sortedData = allData.sort((a, b) => new Date(b.Date1__c) - new Date(a.Date1__c));

      // Export the sorted data to Excel
      if (sortedData.length > 0) {
        handleExport(sortedData);  // Call export function
      } else {
        console.log('No data available to export');
      }
    } catch (error) {
      console.error("Error fetching data for Excel export:", error);
    } finally {
      setExcloading(false);  // Hide loading indicator after export process
    }
  };



  const handleExport = (attendanceallData) => {
    if (!attendanceallData || attendanceallData.length === 0) {
      alert('No data available to export');
      return;
    }

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(attendanceallData.map(item => {
      const logInTimeStr = item?.Log_In__c || 'N/A';
      const logOutTimeStr = item?.Log_Out__c || 'N/A';
      let isPresent = false;
      const logInTime = parseTime(logInTimeStr);
      const logOutTime = parseTime(logOutTimeStr);

      if (logInTime && logOutTime) {
        const timeDifference = logOutTime - logInTime;
        const eightHoursInMs = 8 * 60 * 60 * 1000;
        isPresent = timeDifference >= eightHoursInMs;
      }
      const attendanceStatus = item?.Status__c === "WO"
      ? "WO"
      : item?.Status__c === "CL"
      ? "CL"
      : item?.Status__c === "PL"
      ? "PL"
      : item?.Status__c === "SL"
      ? "SL"
      : item?.Status__c === "HLD"
      ? "HLD"
      : isPresent
      ? "P"
      : "A";
      return {
        'Division': item?.Emp_Attendance__r?.Division__c || 'N/A',
        'Employee Code': item?.Emp_Attendance__r?.EmpId__c || 'N/A',
        'Employee Name': item?.Emp_Attendance__r?.Name || 'N/A',
        'Designation': item?.Emp_Attendance__r?.Designation__c || 'N/A',
        'Zone': item?.Emp_Attendance__r?.Zone__c || 'N/A',
        'Date': formatDateStringDDMMYYYY(item?.Date1__c) || 'N/A',
        'Log In Time': logInTimeStr,
        'Log Out Time': logOutTimeStr,
        'Attendance Status': attendanceStatus
      };
    }));
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");

    // Export the workbook
    XLSX.writeFile(workbook, "Attendance_Report.xlsx");
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchData(true, lastRecordId); // Pass true for load more and the lastRecordId
    }
  };

  return (
    <div className="markAttendance_section container-fluid">
      {excloading && <Loader />}
      <div className="row mb-5">
        <Card className="monthlyClaim_mobile">
          <Card.Body>
            <h4 className="mb-4 text-center">Attendance Report</h4>
            <div className="filter-section mb-4">
              <label style={{ marginRight: '1rem' }}>
                Start Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  value={startDate}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={e => setStartDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: '1rem' }}>
                End Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: '1rem' }}>
                EmpId
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  maxLength={4}
                  style={{
                    width: "8rem",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={filterEmpId}
                  onChange={e => setFilterEmpId(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </label>
              <span onClick={handleSubmit}>
                <CommonButton title={"Submit"} className="btn btn-primary" />
              </span>
              {dataFetched && (
                <span style={{ marginLeft: "10px" }} onClick={exportToExcel}>
                  <CommonButton title={"Download Excel"} />
                </span>
              )}

            </div>
            {error && <span className="dcr-form-errors">{error}</span>}
            {loading ? (
              <div>Loading...</div>
            ) : (
              dataFetched && (

                <div
                  id="table-to-xls"
                  className="table-wrapper-scroll-y custom-scrollbar"
                  style={{ padding: '20px', overflow: 'auto' }}
                >
                  <table
                    className="w-100"
                    style={{ textAlign: "center" }}
                    ref={ref}
                  >
                    <thead>
                      <tr className="ladder_heading">
                        <th className="custom-header">Division</th>
                        <th className="custom-header">Employee Code</th>
                        <th className="custom-header">Employee Name</th>
                        <th className="custom-header">Designation</th>
                        <th className="custom-header">Zone</th>
                        <th className="custom-header">Date</th>
                        <th className="custom-header">Log In Time</th>
                        <th className="custom-header">Log Out Time</th>
                        <th className="custom-header">Attendance Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allAttendance?.map((attendance, index) => {
                        const logInTimeStr = attendance?.Log_In__c || 'N/A';
                        const logOutTimeStr = attendance?.Log_Out__c || 'N/A';
                        let isPresent = false;
                        const logInTime = parseTime(logInTimeStr);
                        const logOutTime = parseTime(logOutTimeStr);

                        if (logInTime && logOutTime) {
                          const timeDifference = logOutTime - logInTime;
                          const eightHoursInMs = 8 * 60 * 60 * 1000;
                          isPresent = timeDifference >= eightHoursInMs;
                        }
                        const attendanceStatus = attendance?.Status__c === "WO"
                        ? "WO"
                        : attendance?.Status__c === "CL"
                        ? "CL"
                        : attendance?.Status__c === "PL"
                        ? "PL"
                        : attendance?.Status__c === "SL"
                        ? "SL"
                        : attendance?.Status__c === "HLD"
                        ? "HLD"
                        : isPresent
                        ? "P"
                        : "A";
                          console.log("attendanceStatus",attendanceStatus)

                        return (
                          <tr key={index} className="ladder_heading_data">
                            <td className='pl-3'>{attendance?.Emp_Attendance__r?.Division__c || 'N/A'}</td>
                            <td className='pl-3'>{attendance?.Emp_Attendance__r?.EmpId__c || 'N/A'}</td>
                            <td className='pl-3'>{attendance?.Emp_Attendance__r?.Name || 'N/A'}</td>
                            <td className='pl-3'>{attendance?.Emp_Attendance__r?.Designation__c || 'N/A'}</td>
                            <td className='pl-3'>{attendance?.Emp_Attendance__r?.Zone__c || 'N/A'}</td>
                            <td className='pl-3'>{formatDateStringDDMMYYYY(attendance?.Date1__c) || 'N/A'}</td>
                            <td className='pl-3'>{logInTimeStr}</td>
                            <td className='pl-3'>{logOutTimeStr}</td>
                            <td className='pl-3' style={{ color: attendanceStatus === "P" ? 'green' : 'red' }}>
                              {attendanceStatus}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </Card.Body>
        </Card>
        <ToastContainer />
        {(hasMore && !isLoading && (allAttendance.length > 0)) &&
          <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
            <CommonButton title={"Load More"} />
          </span>
        }
      </div>
    </div>
  );
});

export default AttendanceReport;