import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import "./SingleClaim.css";
import { useState } from "react";
import "./../MonthlyTour/PlanMTP.css"
import "./../../Common/CommonDropdown.css";
import {
  claimOptons,
  conveynanceOption,
  stationOptions,
  transportOptions,
  typeOfGeneralExpense,
} from "../../Common/Constants";
import DeleteIcon from "@mui/icons-material/Delete";
import BackButton from "../../Common/BackButton";
import CommonButton from "../../Common/CommonButton";
import { useSelector } from "react-redux";
import { createExpensesApi } from "../../services/expenses/createExpensesApi";
import { Card } from "react-bootstrap";
import Loader from "../../Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import "../Expense/ExpenseClaim.css"

const SingleClaim = ({ getExpenses }) => {
  const [claims, setClaims] = useState([
    {
      claimType: claimOptons[2],
      claimDate: "",
      claimKm: "",
      conveynanceMode: conveynanceOption[0],
      station: stationOptions[0],
      city: "",
      fromDate: "",
      toDate: 0,
      noOfDays: "",
      fromPlace: "",
      toPlace: "",
      modeOfTransport: transportOptions[0],
      departureStation: "",
      departureDate: "",
      departureTime: "",
      arrivalStation: "",
      arrivalDate: "",
      arrivalTime: "",
      ticketNo: "",
      claimAmount: "",
      purposeOfTravel: "",
      generalExpenseDate: "",
      generalExpenseAmount: "",
      generalExpenseType: typeOfGeneralExpense[0],
      dailyTotalAmount: 0
    },
  ]);
  const [claimsErrors, setClaimsErrors] = useState([]);
  const [finalClaims, setFinalClaims] = useState([]);
  const [isClaimsError, setIsClaimsError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const inputRef = useRef(null);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);
  const userIds = useSelector((state) => state?.userDetails?.details?.Id);
  const [selectFileName, setSelectFileName] = useState(Array(claims.length).fill(''));
  const [photoError, setPhotoError] = useState();
  const [dailyTotalAmount, setDailyTotalAmount] = useState(0);
  const [localTotalAmount, setLocalTotalAmount] = useState(0);
  const [numericError, setNumericError] = useState('');
  const [loading, setLoading] = useState();
  const [editableNoOfDays, setEditableNoOfDays] = useState(true);

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event, index) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      setPhotoError('Please select a file.');
      return;
    }
    const allowedExtensions = ['jpg', 'jpeg', 'pdf', 'png'];
    const updatedClaims = [...claims];
    const newSelectedFileNames = [];

    for (let i = 0; i < files.length; i++) {
      const fileObj = files[i];
      const fileName = fileObj.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setPhotoError('Please select a JPG, JPEG, PNG, or PDF file.');
        continue;
      }
      newSelectedFileNames.push(fileName);
      // setSelectFileName(files[0].name)
      const reader = new FileReader();
      reader.onload = (upload) => {
        const base64String = upload.target.result.split(",")[1];
        updatedClaims[index + i] = {
          ...updatedClaims[index + i],
          imageData: base64String,
          fileType: fileExtension,
        };
        setPhotoError('')
        setClaims(updatedClaims);
      };
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
      reader.readAsDataURL(fileObj);
    }
    setSelectFileName(prevFileNames => {
      const newFileNames = [...prevFileNames];
      newFileNames[index] = newSelectedFileNames.join(', ');
      return newFileNames;
    });
  };

  useEffect(() => {
    let date = new Date();
    let maxDate = date
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .split("/")
      .reverse()
      .join("-");
    let minDate = new Date(date.setDate(date.getDate() - 6))
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .split("/")
      .reverse()
      .join("-");
    setDateRange({
      min: minDate,
      max: maxDate,
    });
  }, []);

  const handleAddClaim = () => {
    const newClaim = {
      claimType: claimOptons[2],
      claimDate: "",
      claimKm: "",
      conveynanceMode: conveynanceOption[0],
      station: stationOptions[0],
      city: "",
      fromDate: "",
      toDate: "",
      noOfDays: "",
      fromPlace: "",
      toPlace: "",
      modeOfTransport: transportOptions[0],
      departureStation: "",
      departureDate: "",
      departureTime: "",
      arrivalStation: "",
      arrivalDate: "",
      arrivalTime: "",
      ticketNo: "",
      claimAmount: "",
      purposeOfTravel: "",
      generalExpenseDate: "",
      generalExpenseAmount: "",
      generalExpenseType: typeOfGeneralExpense[0],
      dailyTotalAmount: 0,
    };
    setClaims([...claims, newClaim]);
  };

  const handleClaimChange = (e, index) => {
    const { name, value } = e.target;
    setClaimsErrors([
      {
        claimType: claimOptons[2],
        claimDate: "",
        claimKm: "",
        conveynanceMode: conveynanceOption[0],
        station: stationOptions[0],
        city: "",
        fromDate: "",
        toDate: 0,
        noOfDays: "",
        fromPlace: "",
        toPlace: "",
        modeOfTransport: transportOptions[0],
        departureStation: "",
        departureDate: "",
        departureTime: "",
        arrivalStation: "",
        arrivalDate: "",
        arrivalTime: "",
        ticketNo: "",
        claimAmount: "",
        purposeOfTravel: "",
        generalExpenseDate: "",
        generalExpenseAmount: "",
        generalExpenseType: typeOfGeneralExpense[0],
        dailyTotalAmount: 0
      },
    ]);
    setIsClaimsError(false);
    let tempClaims = [...claims];
    tempClaims[index][e.target.name] = e.target.value;
    // debugger;
    if (name === 'station') {
      tempClaims[index]['city'] = '';
      tempClaims[index]['fromDate'] = '';
      tempClaims[index]['toDate'] = '';
      tempClaims[index]['noOfDays'] = '';
      tempClaims[index]['dailyTotalAmount'] = 0;
      setDailyTotalAmount(0);
      // setClaims([{}])
    }
    if (name === 'claimType') {
      tempClaims[index]['modeOfTransport'] = '';
      tempClaims[index]['departureStation'] = '';
      tempClaims[index]['departureStation'] = '';
      tempClaims[index]['arrivalStation'] = '';
      tempClaims[index]['arrivalDate'] = '';
      tempClaims[index]['purposeOfTravel'] = '';
      tempClaims[index]['fromPlace'] = '';
      tempClaims[index]['conveynanceMode'] = '';
      tempClaims[index]['toPlace'] = '';
      tempClaims[index]['claimKm'] = '';
      tempClaims[index]['generalExpenseType'] = '';
      tempClaims[index]['generalExpenseDate'] = '';
      tempClaims[index]['generalExpenseAmount'] = '';
      tempClaims[index]['city'] = '';
      tempClaims[index]['fromDate'] = '';
      tempClaims[index]['toDate'] = '';
      tempClaims[index]['noOfDays'] = '';
      tempClaims[index]['dailyTotalAmount'] = '';
      setDailyTotalAmount(0);
      setLocalTotalAmount(0);
      // setSelectFileName('');
    }

    const stationValue = tempClaims[index]['station'];
    const selectClaimtypes = tempClaims[index]['claimType'];
    const selectconveynance = tempClaims[index]['conveynanceMode'];
    // if (name === 'station' && designation !== 'ASM' && (value === 'Out Station Hotel' || value === "Out Station Daily Allownace")) {
    //   setHotelError(true);
    // } else {
    //   setHotelError(false);
    // }
    if (name === 'toDate') {
      const fromDate = tempClaims[index].fromDate;
      const daysDifference = dateDiffInDays(new Date(fromDate), new Date(value));
      tempClaims[index]['noOfDays'] = daysDifference;
      setEditableNoOfDays(false);
    }
    setClaims(tempClaims);

    if (name === 'dailyTotalAmount') {
      tempClaims[index]['totalAmount'] = value;
    }

    if (name === 'noOfDays' || name === 'fromDate') {
      // const numberOfDays = parseInt(value, 10);
      let numberOfDays;
      if (name === 'noOfDays') {
        numberOfDays = parseInt(value, 10);
      } else if (name === 'fromDate') {
        numberOfDays = 1;
      }

      calculateDailyTotalAmount(tempClaims[index], numberOfDays);
      // if (selectClaimtypes === "Daily Allowance") {
      //   if (designation === "VSE" || designation === "BE") {
      //     if ((division === "AH" || division === "PH" || division === "PET") && stationValue === "HQ") {
      //       const amountPerDay = 175;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PH" || division === "PET") && stationValue === "EX HQ") {
      //       const amountPerDay = 225;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "AH" && stationValue === "Out Station Hotel") {
      //       setDailyTotalAmount('');
      //     }
      //     else if (division === "PH" && stationValue === "Out Station Hotel") {
      //       const amountPerDay = 800;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PET" && stationValue === "Out Station Hotel") {
      //       const amountPerDay = 700;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "PET" || division === "PH") && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 300;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "AH" && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 650;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "PET" || division === "PH") && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 400;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //   }

      //   if (designation === "ASM" || designation === "ABM") {
      //     if ((division === "AH" || division === "PET") && stationValue === "HQ") {
      //       const amountPerDay = 225;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "HQ") {
      //       const amountPerDay = 200;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PET") && stationValue === "EX HQ") {
      //       const amountPerDay = 300;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "EX HQ") {
      //       const amountPerDay = 250;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PH" || division === "PET") && stationValue === "Out Station Hotel") {
      //       const amountPerDay = 1200;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PET") && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 350;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PET") && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 400;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //   }

      //   if (designation === "RSM" || designation === "RBM") {
      //     if ((division === "AH" || division === "PET") && stationValue === "HQ") {
      //       const amountPerDay = 250;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "HQ") {
      //       const amountPerDay = 225;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PET") && stationValue === "EX HQ") {
      //       const amountPerDay = 325;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "EX HQ") {
      //       const amountPerDay = 275;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PH" || division === "PET") && stationValue === "Out Station Hotel") {
      //       const amountPerDay = 1500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PET") && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 600;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "PH" || division === "PET") && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "AH" && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 600;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //   }

      //   if (designation === "ZSM") {
      //     if (division === "AH" && stationValue === "HQ") {
      //       const amountPerDay = 300;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "HQ") {
      //       const amountPerDay = 250;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "AH" && stationValue === "EX HQ") {
      //       const amountPerDay = 400;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "EX HQ") {
      //       const amountPerDay = 300;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PH") && stationValue === "Out Station Hotel") {
      //       const amountPerDay = 1800;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "AH" && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 800;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (division === "PH" && stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 600;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if ((division === "AH" || division === "PH") && stationValue === "Out Station Own Arrangement") {
      //       const amountPerDay = 600;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //   }
      //   if (designation === "SM") {
      //     if (stationValue === "HQ") {
      //       const amountPerDay = 400;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (stationValue === "EX HQ") {
      //       const amountPerDay = 500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (stationValue === "Out Station Hotel") {
      //       const amountPerDay = 3500;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (stationValue === "Out Station Daily Allownace") {
      //       const amountPerDay = 800;
      //       const calculatedTotalAmount = numberOfDays * amountPerDay;
      //       setDailyTotalAmount(calculatedTotalAmount);
      //     }
      //     else if (stationValue === "Out Station Own Arrangement") {
      //       setDailyTotalAmount('');
      //     }
      //   }
      // }
    }
    if (name === "claimKm") {
      const claimKmValue = parseFloat(value);
      const selectconveynance = tempClaims[index].conveynanceMode;
      let calculatedTotalAmount = 0;
      if (selectClaimtypes === "Local Conveyance") {
        if (designation === "VSE" || designation === "BE" || designation === "Senior Veterinary Sales Executive" || designation === "Veterinary Sales Executive") {
          if ((division === "AH" || division === "PET") && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Bike") {
            const amountPerDay = 3.25;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Car") {
            const amountPerDay = 8;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
        }

        if (designation === "ASM" || designation === "ABM" || designation === "FTE" || designation === "KCM") {
          if ((division === "AH" || division === "PET") && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Bike") {
            const amountPerDay = 3.25;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "AH" && selectconveynance === "Car") {
            const amountPerDay = 6;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if ((division === "PH" || division === "PET") && selectconveynance === "Car") {
            const amountPerDay = 8;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
        }

        if (designation === "RSM" || designation === "RBM" || designation === "TSM") {
          if ((division === "AH" || division === "PET") && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Bike") {
            const amountPerDay = 3.25;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "AH" && selectconveynance === "Car") {
            const amountPerDay = 7;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if ((division === "PH" || division === "PET") && selectconveynance === "Car") {
            const amountPerDay = 8;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
        }

        if (designation === "ZSM") {
          if (division === "AH" && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Bike") {
            const amountPerDay = 3.25;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if ((division === "AH" || division === "PH") && selectconveynance === "Car") {
            const amountPerDay = 8;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
        }

        if (designation === "SM") {
          if (division === "AH" && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if (division === "PH" && selectconveynance === "Bike") {
            const amountPerDay = 3.5;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
          else if ((division === "AH" || division === "PH") && selectconveynance === "Car") {
            const amountPerDay = 8;
            calculatedTotalAmount = claimKmValue * amountPerDay;
            setLocalTotalAmount(calculatedTotalAmount);
          }
        }
      }
      tempClaims[index].totalAmount = calculatedTotalAmount;
    }
    setClaims(tempClaims);
  };
  const calculateDailyTotalAmount = (claim, numberOfDays) => {
    if (claim.claimType === "Daily Allowance" && (designation === "VSE" || designation === "BE" || designation === "ASM" || designation === "ABM" || designation === "RSM" || designation === "RBM" || designation === "ZSM" || designation === "SM" || designation == 'FTE' || designation == 'KCM' || designation == "TSM" || designation == "Senior Veterinary Sales Executive" || designation === "Veterinary Sales Executive")) {
      let amountPerDay = 0;
  
      // VSE or BE or sr vse designations
      if (designation === "VSE" || designation === "BE" || designation === "Senior Veterinary Sales Executive" || designation === "Veterinary Sales Executive") {
        if (["AH", "PH", "PET"].includes(division)) {
          if (claim.station === "HQ") {
            amountPerDay = 175;
          } else if (claim.station === "EX HQ") {
            amountPerDay = 225;
          } else if (division === "PH" && claim.station === "Out Station Hotel") {
            amountPerDay = 800;
          } else if (division === "PET" && claim.station === "Out Station Hotel") {
            amountPerDay = 700;
          } else if (["PET", "PH"].includes(division) && claim.station === "Out Station Daily Allowance") {
            amountPerDay = 300;
          } else if (claim.station === "Out Station Own Arrangement") {
            amountPerDay = division === "AH" ? 650 : 400;
          } 
        }
      }
  
      // ASM or ABM designations
      else if (designation === "ASM" || designation === "ABM" || designation === "FTE" || designation === "KCM") {
        if ((["AH", "PET"].includes(division) && claim.station === "HQ")) {
          amountPerDay = 225;
        } else if (division === "PH" && claim.station === "HQ") {
          amountPerDay = 200;
        } else if ((["AH", "PET"].includes(division) && claim.station === "EX HQ")) {
          amountPerDay = 300;
        } else if (division === "PH" && claim.station === "EX HQ") {
          amountPerDay = 250;
        } else if ((["AH", "PH", "PET"].includes(division) && claim.station === "Out Station Hotel")) {
          amountPerDay = 1200;
        } else if ((["AH", "PET"].includes(division) && claim.station === "Out Station Daily Allowance")) {
          amountPerDay = 500;
        } else if (division === "PH" && claim.station === "Out Station Daily Allowance") {
          amountPerDay = 350;
        } else if ((["AH", "PET"].includes(division) && claim.station === "Out Station Own Arrangement")) {
          amountPerDay = 500;
        } else if (division === "PH" && claim.station === "Out Station Own Arrangement") {
          amountPerDay = 400;
        }
      }
  
      // RSM or RBM designations
      else if (designation === "RSM" || designation === "RBM" || designation === "TSM") {
        if ((["AH", "PET"].includes(division) && claim.station === "HQ")) {
          amountPerDay = 250;
        } else if (division === "PH" && claim.station === "HQ") {
          amountPerDay = 225;
        } else if ((["AH", "PET"].includes(division) && claim.station === "EX HQ")) {
          amountPerDay = 325;
        } else if (division === "PH" && claim.station === "EX HQ") {
          amountPerDay = 275;
        } else if ((["AH", "PH", "PET"].includes(division) && claim.station === "Out Station Hotel")) {
          amountPerDay = 1500;
        } else if ((["AH", "PET"].includes(division) && claim.station === "Out Station Daily Allowance")) {
          amountPerDay = 600;
        } else if (division === "PH" && claim.station === "Out Station Daily Allowance") {
          amountPerDay = 500;
        } else if ((["PH", "PET"].includes(division) && claim.station === "Out Station Own Arrangement")) {
          amountPerDay = 500;
        } else if (division === "AH" && claim.station === "Out Station Own Arrangement") {
          amountPerDay = 600;
        }
      }
  
      // ZSM designation
      else if (designation === "ZSM") {
        if (division === "AH" && claim.station === "HQ") {
          amountPerDay = 300;
        } else if (division === "PH" && claim.station === "HQ") {
          amountPerDay = 250;
        } else if (division === "AH" && claim.station === "EX HQ") {
          amountPerDay = 400;
        } else if (division === "PH" && claim.station === "EX HQ") {
          amountPerDay = 300;
        } else if ((["AH", "PH"].includes(division) && claim.station === "Out Station Hotel")) {
          amountPerDay = 1800;
        } else if (division === "AH" && claim.station === "Out Station Daily Allowance") {
          amountPerDay = 800;
        } else if (division === "PH" && claim.station === "Out Station Daily Allowance") {
          amountPerDay = 600;
        } else if ((["AH", "PH"].includes(division) && claim.station === "Out Station Own Arrangement")) {
          amountPerDay = 600;
        }
      }
  
      // SM designation
      else if (designation === "SM") {
        if (claim.station === "HQ") {
          amountPerDay = 400;
        } else if (claim.station === "EX HQ") {
          amountPerDay = 500;
        } else if (claim.station === "Out Station Hotel") {
          amountPerDay = 3500;
        } else if (claim.station === "Out Station Daily Allowance") {
          amountPerDay = 1200;
        } else if (claim.station === "Out Station Own Arrangement") {
          amountPerDay = 0; // Assuming you want to set it to zero based on the commented code
        }
      }
  
      const calculatedTotalAmount = numberOfDays * amountPerDay;
      claim.dailyTotalAmount = calculatedTotalAmount;
      setDailyTotalAmount(calculatedTotalAmount);
    }
  };
  

  // const handeClaimCopy = (claim) => {
  //   let claimToBeCopy = { ...claim, id: Math.random() };
  //   let tempClaims = [...claims, claimToBeCopy];
  //   setClaims(tempClaims);
  // };

  const handeClaimDelete = (index) => {
    if (claims.length > 1) {
      let tempClaims = [...claims];
      tempClaims.splice(index, 1);
      setClaims(tempClaims);
    }
  };

  const checkClaimsError = (claims) => {
    let isClaimError = false;
    let claimsErrorArray = [];
    claims.forEach((claim, index) => {
      switch (claim.allowanceType) {
        case claimOptons[0]:
          if (claim.departureDate === "" || claim.ArrivalDate === "" || claim.TAClaimAmount === "" || claim.departureStation === "" || claim.ArrivalStation === "" || claim.TravelAllowanceType === "") {
            isClaimError = true;
            claimsErrorArray.push(true);
          } else {
            claimsErrorArray.push(false);
          }
          break;

        case claimOptons[1]:
          // if (claim.DailyAllowanceType === "" || claim.city === "" || claim.fromDate === "" || claim.Days === "" || claim.fileData === "" || claim.fileData === undefined) {
          if (claim.DailyAllowanceType === "" || claim.city === "" || claim.DailyAllowanceType ===""|| claim.DailyAllowanceType === null|| claim.DailyAllowanceType==="Select Allowance Type"|| ((claim.DailyAllowanceType === "HQ" || claim.DailyAllowanceType === "EX HQ") && (claim.DADate === "")) ||
            ((claim.DailyAllowanceType === "Out Station Hotel") && (claim.fromDate === "" || claim.toDate === "" || claim.DAamount === "" || claim.Days === "")) ||
            ((claim.DailyAllowanceType === "Out Station Daily Allowance" || claim.DailyAllowanceType === "Out Station Own Arrangement") && (claim.fromDate === "" || claim.toDate === "" || claim.Days === ""))) {
            isClaimError = true;
            claimsErrorArray.push(true);
          } else {
            claimsErrorArray.push(false);
          }
          break;


        case claimOptons[2]:
          if (claim.km === "" || claim.fromDate === "" || claim.toDate === "" || claim.PlaceFrom === "" || claim.PlaceTo === "" || claim.LocalConveyanceType === "") {
            isClaimError = true;
            claimsErrorArray.push(true);
          } else {
            claimsErrorArray.push(false);
          }
          break;

        case claimOptons[3]:
          if (claim.ExDate === "" || claim.GEClaimAmount === "" || claim.TypeofGeneralExpense ==="") {
            isClaimError = true;
            claimsErrorArray.push(true);
          } else {
            claimsErrorArray.push(false);
          }
          break;

        default:
          claimsErrorArray.push(false);
          break;
      }
    });
    if (isClaimError) {
      setClaimsErrors(claimsErrorArray);
      setIsClaimsError(true);
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
      setIsClaimsError(false);
      setFinalClaims(claims);
      setLocalTotalAmount(0);
      setDailyTotalAmount(0);
      setPhotoError('');
      setSelectFileName('')
    }
  };

useEffect(() => {
  if (isSubmit) {
    postClaimsToSf();
  }
}, [isSubmit, finalClaims]);

  const postClaimsToSf = async () => {
    setLoading(true);
    try {
      const result = await createExpensesApi(finalClaims, accessToken);
      setLoading(false);
      setIsSubmit(false);
      setClaimsErrors([]);
      setIsClaimsError(false);
      if (result?.data?.message.trim() === "Please check if the DCR is filled for mentioned expense claim") {
        toast.error("Please check if the DCR is filled for mentioned expense claim.")
      }
      else if (result?.data?.message.trim() === "Expense claims calculated successfully") {
        toast.success("Expense claim created successfully.");
        setClaims([
          {
            claimType: claimOptons[2],
            claimDate: "",
            claimKm: "",
            conveynanceMode: conveynanceOption[0],
            station: stationOptions[0],
            city: "",
            fromDate: "",
            toDate: "",
            noOfDays: "",
            fromPlace: "",
            toPlace: "",
            modeOfTransport: transportOptions[0],
            departureStation: "",
            departureDate: "",
            departureTime: "",
            arrivalStation: "",
            arrivalDate: "",
            arrivalTime: "",
            ticketNo: "",
            claimAmount: "",
            purposeOfTravel: "",
            generalExpenseDate: "",
            generalExpenseAmount: "",
            generalExpenseType: typeOfGeneralExpense[0],
          },
        ]);
        getExpenses();

      }
      else if (result?.data?.message.trim() === "Please check if the DCR is filled for mentioned expense claim / Or other types of Tour Plans are not approved") {
        toast.error("Please check if the DCR is filled for mentioned expense claim / Or other types of Tour Plans are not approved")
      }
      else if (result?.data?.message.trim() === "Not all DCR Surveys for the given date are completed. Please complete all tour plans before creating an expense claim.") {
        toast.error("Not all DCR Surveys for the given date are completed. Please complete all tour plans before creating an expense claim.")
      }
      else if (result?.data?.message.trim() === "Daily Allowance already filled for mentioned date") {
        toast.error("Daily Allowance already filled for mentioned date")
      }
      else if (result?.data?.message.trim() === "Local Conveyance already filled for mentioned date") {
        toast.error("Local Conveyance already filled for mentioned date")
      }
      else if (result?.data?.message.trim() === "Travel Allowance already filled for mentioned date") {
        toast.error("Travel Allowance already filled for mentioned date")
      }
      else if (result?.data?.message.trim() === "General Expense already filled for mentioned date") {
        toast.error("General Expense already filled for mentioned date")
      }
      else {
        toast.error("An error occured while creating new claim")
      }

    } catch (error) {
      setLoading(false);
      setIsSubmit(false);
      setClaimsErrors([]);
      setIsClaimsError(false);
    }
  };

  const handleSave = async () => {
    setIsSubmit(false);
    const claimsToSave = [];
    claims.forEach((claim, index) => {
      let imageData = claim.imageData;
      let fileType = claim.fileType;
      const totalAmount = claim.dailyTotalAmount ? parseFloat(claim.dailyTotalAmount) : 0;
      const DAamount = (claim.station === 'VSE') ? totalAmount : dailyTotalAmount;
      if (claim.claimType === claimOptons[0]) {
        let travelAllowance = {
          km: null,
          empId: empId,
          UserId: userIds,
          allowanceType: claim.claimType,
          DailyAllowanceType: null,
          city: null,
          fromDate: claim.departureDate,
          toDate: claim.arrivalDate,
          Days: null,
          TravelAllowanceType: claim.modeOfTransport,
          departureStation: claim.departureStation,
          departureDate: claim.departureDate,
          departureTime: null,
          ArrivalStation: claim.arrivalStation,
          ArrivalDate: claim.arrivalDate,
          ArrivalTime: null,
          TicketNumber: null,
          TAClaimAmount: claim.claimAmount,
          PurposeOfTravel: claim.purposeOfTravel,
          ExDate: null,
          PlaceFrom: null,
          PlaceTo: null,
          LocalConveyanceType: null,
          TypeofGeneralExpense: null,
          GEClaimAmount: null,
          fileData: imageData,
          fileType: fileType,
        };
        claimsToSave.push(travelAllowance);
      } else if (claim.claimType === claimOptons[1]) {
        let dailyAllowance = {
          km: null,
          empId: empId,
          UserId: userIds,
          allowanceType: claim.claimType,
          DailyAllowanceType: claim.station,
          city: claim.city,
          DADate: (claim.station === 'HQ' || claim.station === 'EX HQ') ? claim.fromDate : null,
          fromDate: (claim.station !== 'HQ' && claim.station !== 'EX HQ') ? claim.fromDate : null,
          toDate: (claim.station !== 'HQ' && claim.station !== 'EX HQ') ? claim.toDate : null,
          Days: (claim.station === 'HQ' || claim.station === 'EX HQ') ? 1 : parseInt(claim.noOfDays),
          TravelAllowanceType: null,
          departureStation: null,
          departureDate: null,
          departureTime: null,
          ArrivalStation: null,
          ArrivalDate: null,
          ArrivalTime: null,
          TicketNumber: null,
          TAClaimAmount: null,
          PurposeOfTravel: null,
          ExDate: (claim.station === 'HQ' || claim.station === 'EX HQ') ? claim.fromDate : null,
          PlaceFrom: null,
          PlaceTo: null,
          LocalConveyanceType: null,
          TypeofGeneralExpense: null,
          GEClaimAmount: null,
          fileData: imageData,
          fileType: fileType,
          DAamount: DAamount,
          checkDCRDate: (claim.station === 'HQ' || claim.station === 'EX HQ') ? claim.fromDate : null,
        };
        claimsToSave.push(dailyAllowance);
      } else if (claim.claimType === claimOptons[2]) {
        let localConvance = {
          km: claim.claimKm,
          empId: empId,
          UserId: userIds,
          allowanceType: claim.claimType,
          DailyAllowanceType: null,
          city: null,
          fromDate: claim.fromDate,
          toDate: claim.toDate,
          Days: null,
          TravelAllowanceType: null,
          departureStation: null,
          departureDate: null,
          departureTime: null,
          ArrivalStation: null,
          ArrivalDate: null,
          ArrivalTime: null,
          TicketNumber: null,
          TAClaimAmount: null,
          PurposeOfTravel: null,
          ExDate: null,
          PlaceFrom: claim.fromPlace,
          PlaceTo: claim.toPlace,
          LocalConveyanceType: claim.conveynanceMode,
          TypeofGeneralExpense: null,
          GEClaimAmount: null,
          fileData: null,
          fileType: null,
        };
        claimsToSave.push(localConvance);
      } else if (claim.claimType === claimOptons[3]) {
        let generalExpense = {
          km: null,
          empId: empId,
          UserId: userIds,
          allowanceType: claim.claimType,
          DailyAllowanceType: null,
          city: null,
          fromDate: null,
          toDate: null,
          Days: null,
          TravelAllowanceType: null,
          departureStation: null,
          departureDate: null,
          departureTime: null,
          ArrivalStation: null,
          ArrivalDate: null,
          ArrivalTime: null,
          TicketNumber: null,
          TAClaimAmount: null,
          PurposeOfTravel: null,
          ExDate: claim.generalExpenseDate,
          PlaceFrom: null,
          PlaceTo: null,
          LocalConveyanceType: null,
          TypeofGeneralExpense: claim.generalExpenseType,
          GEClaimAmount: claim.generalExpenseAmount,
          fileData: imageData,
          fileType: fileType,
          checkDCRDate: claim.generalExpenseDate,
        };
        claimsToSave.push(generalExpense);
      } else {
        console.warn(`Unknown claim type: ${claim.claimType} at index ${index}`);
      }
    });
    // console.log("Claims to Save: ", claimsToSave);
  
    checkClaimsError(claimsToSave);
  };
  const getThreeDaysAgo = () => {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 7);
    return threeDaysAgo.toISOString().split('T')[0];
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setNumericError('Please enter only numbers.');
    } else {
      setNumericError('');
    }
  };
  const handlenoOfDays = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setNumericError('Please enter only numbers.');
    } else {
      setNumericError('');
    }
  };
  const handleClaimAmount = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setNumericError('Please enter only numbers.');
    } else {
      setNumericError('');
    }
  };
  const handleDistance = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setNumericError('Please enter only numbers.');
    } else {
      setNumericError('');
    }
  };
  const handleGeAmount = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setNumericError('Please enter only numbers.');
    } else {
      setNumericError('');
    }
  };

  const handleToDateChange = (e, index) => {
    const newToDate = e.target.value;
    const fromDate = claims[index].fromDate;
    const daysDifference = dateDiffInDays(new Date(fromDate), new Date(newToDate));
    handleClaimChange({
      target: {
        name: 'noOfDays',
        value: daysDifference
      }
    }, index);
    setEditableNoOfDays(false);
  }


  const dateDiffInDays = (date1, date2) => {
    const timeDiff = date2.getTime() - date1.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    return Math.ceil(dayDiff);
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const enteredAmount = value === "" ? 0 : parseFloat(value);
  
    if (isNaN(enteredAmount) && value !== "") {
      setNumericError("Please enter a valid number");
      return;
    } else {
      setNumericError(null);
    }
  
    const updatedClaims = [...claims];
    updatedClaims[index][name] = value === "" ? 0 : enteredAmount;
    setClaims(updatedClaims);
  
    if (name === "dailyTotalAmount") {
      setDailyTotalAmount(value === "" ? 0 : enteredAmount);
    }
  };
  return (
    <>
      <div className="m-4">
        <div className="row mb-4">
          <div className="col-lg-10 col-md-10 col-sm-12 col-12">
            <div className="mb-2">
              <BackButton title={"Create New Claims"} />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12 button_expense">
            <div onClick={handleAddClaim}>
              <span className="leaveApply_button">
                <CommonButton title={"Add New Claim"} width={"10rem"} />
              </span>
            </div>
          </div>
        </div>

        <div className="expenses_outer">
          {claims.map((claim, index) => {
            return (
              <div className="singleExpense_container mt-3" key={claim.id}>
                <Card>
                  <Card.Body style={{ padding: "10px" }}>
                    <p className="mb-1" style={{ textAlign: 'center', fontSize: '18px', textDecoration: 'underline' }}>Claim - {index + 1}</p>
                    <div className="row">
                      <div className=" row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 singleClaim_claimtype">
                          <p className="PlanMTP_leftHead">Claim Type *</p>
                          <Form.Select
                            aria-label="Default select example"
                            className="CommonDropdown PlanMTP_leftDrop"
                            value={claim.claimType}
                            name="claimType"
                            onChange={(e) => {
                              handleClaimChange(e, index);
                            }}
                            style={{ fontFamily: 'GilroyMedium' }}
                          >
                            {claimOptons.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                        </div>

                        {claim.claimType === claimOptons[0] && (
                          <>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 singleClaim_claimtype input-spacing">
                              <p className="PlanMTP_leftHead">Transport Class *</p>
                              <Form.Select
                                aria-label="Default select example"
                                className="CommonDropdown PlanMTP_leftDrop"
                                // labelId="claimType-label"
                                label="Transport Class"
                                value={claim.modeOfTransport}
                                name="modeOfTransport"
                                onChange={(e) => {
                                  handleClaimChange(e, index);
                                }}
                                style={{ fontFamily: 'GilroyMedium' }}
                              >
                                {transportOptions.map((option) => {
                                  return (
                                    <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>
                                  );
                                })}
                              </Form.Select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              <p className="PlanMTP_leftHead">Departure Station *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="departureStation"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.departureStation}
                              />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              <p className="PlanMTP_leftHead">Departure Date *</p>
                              <input
                                className="CommonDropdown form-select"
                                type="date"
                                name="departureDate"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.departureDate}
                                style={{
                                  width: "100%",
                                  color: "rgb(46, 49, 146)",
                                  fontSize: "14px",
                                  backgroundImage: "none",
                                  paddingRight: "14px",
                                }}
                                min={getThreeDaysAgo()}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>
                            {/* <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                              <p className="PlanMTP_leftHead">Departure Time *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="Time"
                                name="departureTime"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.departureTime}
                              />
                            </div>{" "} */}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">Arrival Station *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="arrivalStation"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.arrivalStation}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-2  col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">Arrival Date *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="date"
                                name="arrivalDate"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.arrivalDate}
                                min={getThreeDaysAgo()}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>{" "}
                            {/* <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12">
                              {" "}
                              <p className="PlanMTP_leftHead">Arrival Time *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="Time"
                                name="arrivalTime"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.arrivalTime}
                              />
                            </div>{" "} */}
                            {/* <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12">
                              {" "}
                              <p className="PlanMTP_leftHead">Ticket No</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="ticketNo"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.ticketNo}
                              />
                            </div>{" "} */}
                            <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12">
                              {" "}
                              <p className="PlanMTP_leftHead">Claim Amount *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="number"
                                name="claimAmount"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.claimAmount}
                                onKeyPress={handleClaimAmount}
                              />
                              {numericError && <span className="dcr-form-errors">{numericError}</span>}
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12">
                              {" "}
                              <p className="PlanMTP_leftHead">Remark</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="purposeOfTravel"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.purposeOfTravel}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-1 col-sm-12 col-12 pt-3">
                              <>
                                {" "}
                                <p className="PlanMTP_leftHead">Attach File</p>
                                <label htmlFor="fileInput" className="fileInputLabel">
                                  <input
                                    id="fileInput"
                                    ref={inputRef}
                                    type="file"
                                    onChange={(e) => handleFileChange(e, index)}
                                    multiple
                                    className="fileInput"
                                  />
                                  <span onClick={handleClick}>
                                    <CommonButton className="mt-2" title={"Attach File"} width={'7rem'} />
                                  </span>
                                  <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>
                                </label>
                              </>
                              {claims.length > 1 && (
                                <span
                                  onClick={() => {
                                    handeClaimDelete(index);
                                  }}
                                >
                                  <>
                                    <DeleteIcon className="MTP_Delete ml-4" />
                                  </>
                                </span>
                              )}
                              <p>{selectFileName[index]}</p>
                              {/* </div> */}
                            </div>
                          </>
                        )}
                        {claim.claimType === claimOptons[1] && (
                          <>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 singleClaim_claimtype input-spacing">
                              <p className="PlanMTP_leftHead">Allowance Type *</p>
                              <Form.Select
                                aria-label="Default select example"
                                className="CommonDropdown PlanMTP_leftDrop"
                                // labelId="claimType-label"
                                label="Daily Allowance Type"
                                value={claim.station}
                                name="station"
                                onChange={(e) => {
                                  handleClaimChange(e, index);
                                }}
                                style={{ fontFamily: 'GilroyMedium' }}
                              >
                                {stationOptions.map((option) => {
                                  return (
                                    <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>
                                  );
                                })}
                              </Form.Select>
                              {/* {hotelError && (
                                <span className="dcr-form-errors">Not applicable for this designation</span>
                              )} */}
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">City *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="city"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.city}
                              />
                            </div>{" "}
                            {(claim.station === 'HQ' || claim.station === 'EX HQ') && (
                              <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                                {" "}
                                <p className="PlanMTP_leftHead">Date *</p>
                                <input
                                  className="CommonDropdown form-select planField"
                                  type="date"
                                  name="fromDate"
                                  onChange={(e) => handleClaimChange(e, index)}
                                  value={claim.fromDate}
                                  min={getThreeDaysAgo()}
                                  max={new Date().toISOString().split('T')[0]}
                                />
                              </div>
                            )}
                            {(claim.station !== 'HQ' && claim.station !== 'EX HQ') && (
                              <>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                                  {" "}
                                  <p className="PlanMTP_leftHead">From Date *</p>
                                  <input
                                    className="CommonDropdown form-select planField"
                                    type="date"
                                    name="fromDate"
                                    onChange={(e) => handleClaimChange(e, index)}
                                    value={claim.fromDate}
                                    min={getThreeDaysAgo()}
                                    max={new Date().toISOString().split('T')[0]}
                                  />
                                </div>{" "}
                                <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                                  {" "}
                                  <p className="PlanMTP_leftHead">To Date *</p>
                                  <input
                                    className="CommonDropdown form-select planField"
                                    type="date"
                                    name="toDate"
                                    onChange={(e) => {
                                      handleClaimChange(e, index);
                                      handleToDateChange(e, index);
                                    }}
                                    value={claim.toDate}
                                    min={getThreeDaysAgo()}
                                    max={new Date().toISOString().split('T')[0]}
                                    disabled={(claim.station === 'HQ' || claim.station === 'EX HQ')}
                                  />
                                </div>
                              </>
                            )}

                            {(claim.station !== 'HQ' && claim.station !== 'EX HQ') && (
                              <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                                {" "}
                                <p className="PlanMTP_leftHead">No Of Days *</p>
                                <input
                                  className="CommonDropdown form-select planField"
                                  type="number"
                                  name="noOfDays"
                                  onChange={(e) => handleClaimChange(e, index)}
                                  value={claim?.noOfDays}
                                  onKeyPress={handlenoOfDays}
                                  disabled={!editableNoOfDays}
                                  readOnly
                                />
                                {numericError && <span className="dcr-form-errors">{numericError}</span>}
                              </div>
                            )}

                            {" "}
                            <div className={`col-lg-2 col-md-2 col-sm-12 col-12 input-spacing ${claim.station === 'HQ' || claim.station === 'EX HQ' ? '' : 'p-3'}`}>
                              {" "}
                              <p className="PlanMTP_leftHead">Total Amount</p>
                              { }
                              { claim.station !== "Out Station Own Arrangement" && claim.station !== "Out Station Hotel" && claim.station !== "Out Station Daily Allowance" ? (
                                <input
                                  className="CommonDropdown form-select planField"
                                  type="text"
                                  name="dailyTotalAmount"
                                  value={claim.dailyTotalAmount}
                                  disabled
                                />
                              ) : (
                                <input
                                  className="CommonDropdown form-select planField"
                                  type="text"
                                  name="dailyTotalAmount"
                                  value={claim.dailyTotalAmount === 0 ? "" : claim.dailyTotalAmount}
                                  onKeyPress={handleKeyPress}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              )}
                              {numericError && <span className="dcr-form-errors">{numericError}</span>}
                            </div>{" "}

                            <div className="col-lg-2 col-md-1 col-sm-12 col-12 p-3">
                              {(claim.station === "Out Station Hotel") && (
                                <>
                                  {" "}
                                  <p className="PlanMTP_leftHead">Attach File</p>
                                  <label htmlFor="fileInput" className="fileInputLabel">
                                    <input
                                      id="fileInput"
                                      ref={inputRef}
                                      type="file"
                                      onChange={(e) => handleFileChange(e, index)}
                                      multiple
                                      className="fileInput"
                                    />
                                    <span onClick={handleClick}>
                                      <CommonButton className="mt-2" title={"Attach File"} width={'7rem'} />
                                    </span>
                                    <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>
                                  </label>
                                </>
                              )}
                              {claims.length > 1 && (
                                <span
                                  onClick={() => {
                                    handeClaimDelete(index);
                                  }}
                                >
                                  <>
                                    <DeleteIcon className="MTP_Delete ml-4" />
                                  </>
                                </span>
                              )}
                              <p>{selectFileName[index]}</p>
                              {/* </div> */}
                            </div>
                          </>
                        )}
                        {claim.claimType === claimOptons[2] && (
                          <>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">From Date *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="date"
                                name="fromDate"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.fromDate}
                                min={getThreeDaysAgo()}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">To Date *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="date"
                                name="toDate"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.toDate}
                                // InputLabelProps={{ shrink: true }}
                                min={getThreeDaysAgo()}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">From Place *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="fromPlace"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.fromPlace}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">To Place *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="toPlace"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.toPlace}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 singleClaim_claimtype input-spacing">
                              <p className="PlanMTP_leftHead"> Conveyance Mode *</p>
                              <Form.Select
                                aria-label="Default select example"
                                className="CommonDropdown PlanMTP_leftDrop"
                                value={claim.conveynanceMode}
                                name="conveynanceMode"
                                onChange={(e) => {
                                  handleClaimChange(e, index);
                                }}
                                style={{ fontFamily: 'GilroyMedium' }}
                              >
                                {conveynanceOption
                                  .filter(option => {
                                    // Filter out "Car" based on VSE with AH or PH division
                                    if ((designation === "VSE" && (division === "AH")) && option === "Car") {
                                      return false;
                                    }
                                    // Filter out "Bike" and "Car" based on ZSM with PET division
                                    if (designation === "ZSM" && division === "PET" && (option === "Car" || option === "Bike")) {
                                      return false;
                                    }
                                    return true;
                                  })
                                  .map((option) => {
                                    return (
                                      <option key={option} value={option} style={{ fontFamily: 'GilroyMedium' }}>
                                        {option}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </div>
                            <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12 mt-2">
                              {" "}
                              <p className="PlanMTP_leftHead">Distance(KM) *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="claimKm"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.claimKm}
                                onKeyPress={handleDistance}
                              />
                              {numericError && <span className="dcr-form-errors">{numericError}</span>}
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-2 pt-3">
                              {" "}
                              <p className="PlanMTP_leftHead">Total Amount</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="LocalTotalAmount"
                                value={claim.totalAmount}
                                disabled
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-1 col-sm-12 col-12">
                              {claims.length > 1 && (
                                <span
                                  onClick={() => {
                                    handeClaimDelete(index);
                                  }}
                                >
                                  <>
                                    <div className="pt-8" style={{ paddingTop: '3rem' }}>
                                      <DeleteIcon className="MTP_Delete ml-4" />
                                    </div>
                                  </>
                                </span>
                              )}
                              {/* </div> */}
                            </div>
                          </>
                        )}
                        {claim.claimType === claimOptons[3] && (
                          <>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 singleClaim_claimtype input-spacing">
                              <p className="PlanMTP_leftHead"> Allowance Type *</p>
                              <Form.Select
                                aria-label="Default select example"
                                className="CommonDropdown PlanMTP_leftDrop"
                                value={claim.generalExpenseType}
                                name="generalExpenseType"
                                onChange={(e) => {
                                  handleClaimChange(e, index);
                                }}
                                style={{ fontFamily: 'GilroyMedium' }}
                              >
                                {typeOfGeneralExpense.map((option) => {
                                  return (
                                    <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>
                                  );
                                })}
                              </Form.Select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">General Expense Date *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="date"
                                name="generalExpenseDate"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim.generalExpenseDate}
                                min={getThreeDaysAgo()}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>{" "}
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 input-spacing">
                              {" "}
                              <p className="PlanMTP_leftHead">Amount *</p>
                              <input
                                className="CommonDropdown form-select planField"
                                type="text"
                                name="generalExpenseAmount"
                                onChange={(e) => handleClaimChange(e, index)}
                                value={claim?.generalExpenseAmount}
                                onKeyPress={handleGeAmount}
                              />
                              {numericError && <span className="dcr-form-errors">{numericError}</span>}
                            </div>
                            <div className="col-lg-2 col-md-1 col-sm-12 col-12 input-spacing">
                              <>
                                {" "}
                                <p className="PlanMTP_leftHead">Attach File </p>
                                <label htmlFor="fileInput" className="fileInputLabel">
                                  <input
                                    id="fileInput"
                                    ref={inputRef}
                                    type="file"
                                    onChange={(e) => handleFileChange(e, index)}
                                    multiple
                                    className="fileInput"
                                  />
                                  <span onClick={handleClick}>
                                    <CommonButton className="mt-2" title={"Attach File"} width={'7rem'} />
                                  </span>
                                  <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>

                                </label>
                              </>
                              {claims.length > 1 && (
                                <span
                                  onClick={() => {
                                    handeClaimDelete(index);
                                  }}
                                >
                                  <>
                                    <DeleteIcon className="MTP_Delete ml-4" />
                                  </>
                                </span>
                              )}
                              <p>{selectFileName[index]}</p>
                              {/* </div> */}
                            </div>
                          </>
                        )}

                      </div>
                      {isClaimsError && claimsErrors[index] && (
                        <div style={{ color: "red" }}>
                          *Mandatory fields cannot be left blank
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}

          <div className="row mt-3">
            <div className="button_expense">
              <span className="leaveApply_button" onClick={handleSave}>
                <CommonButton title={"Save"} width={"10rem"} />
              </span>
            </div>
            {/* <div className="col-lg-4 col-md-4 col-sm-12 col-12 button_expense">
            <span className="leaveApply_button">
              <CommonButton title={"Send for Approval"} />
            </span>
          </div> */}
            {/* <div className="col-lg-2 col-md-2 pt-3 col-sm-12 col-12 button_expense">
              <div onClick={handleAddClaim}>
                <span className="leaveApply_button">
                  <CommonButton title={"Add Claim"} width={"10rem"} />
                </span>
              </div>
            </div> */}
            <div className="col-4"></div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <ToastContainer />
    </>
  );
};

export default SingleClaim;
